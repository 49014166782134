(function() {
  /**
   * @description prevents Apple autozooming
   * on input fields when inputs have a font size < 16px
   **/
    var iOS = false;
    var discardTypes = ['checkbox', 'radio', 'file', 'button', 'image', 'submit', 'reset', 'hidden'];

    iOS = /\b(iPad|iPhone|iPod)\b/i.test(navigator.userAgent);

    if (iOS) {
      preventZoomOnFocus();
    }

    function preventZoomOnFocus() {
      document.documentElement.addEventListener("touchstart", onTouchStart);
      document.documentElement.addEventListener("focusin", onFocusIn);
    }

    function onTouchStart(evt) {
      var tagName = evt.target.tagName;

      if (tagName !== 'SELECT'
          && tagName !== 'TEXTAREA'
          && (tagName !== 'INPUT' || discardTypes.indexOf(evt.target.getAttribute('type')) > -1)
      ) {
        return;
      }

      setViewport('width=device-width, initial-scale=1.0, user-scalable=0');
    }

    function onFocusIn(evt) {
      setViewport('width=device-width, initial-scale=1.0, user-scalable=1');
    }

    function setViewport(value) {
      var viewport = document.documentElement.querySelector('head meta[name="viewport"]');
      if (viewport?.length) {
        viewport.setAttribute("content", value);
      } else {
        viewport = document.createElement("meta");
        viewport.setAttribute("name", "viewport");
        viewport.setAttribute("content", value);
        document.getElementsByTagName('head')[0].appendChild(viewport);
      }
    }
})();